import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Romain = () => {
  return (
    <Layout>
      <Head title="Frederick Fullerton" />
      <h3>Frederick Fullerton</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Purgatory / United States
      </h4>
      <p>
        <OutboundLink href="https://frederickfullerton.blogspot.com/">
          Website
        </OutboundLink>
      </p>
      {/* <p>
        <OutboundLink href="https://ello.co/frederickfullerton">
          Ello
        </OutboundLink>
      </p> */}
      {/* <img
        src="https://assets1.ello.co/uploads/asset/attachment/15085581/ello-optimized-e9ee22f4.jpg"
        alt="Frederick Fullerton art"
      /> */}
      <p>
        <em>
          In this part of New England, farmers normally cut hay three times in a
          season. With luck they may cut four times, weather permitting. The
          final haying will be sometime from mid to late October. The photo
          shows a field, part of which has been recently hayed. Another part has
          been planted with corn.
        </em>
      </p>
      {/* <img
        src="https://assets0.ello.co/uploads/asset/attachment/14878472/ello-optimized-f760e48e.jpg"
        alt="Frederick Fullerton art"
      /> */}
      <p>
        <em>
          A quick voyage on Narragansett Bay from Pawtuxet Cove to Hog Island
          and back. Disclaimer: The actual time on the water was quick, but
          spent more time on the island. Total time of trip: ca. 2.5 hours.
        </em>
      </p>
      {/* <img
        src="https://assets2.ello.co/uploads/asset/attachment/13857248/ello-optimized-3335737d.jpg"
        alt="Frederick Fullerton art"
      /> */}
      <h3>A Change in the Air</h3>
      <div className="poem">
        <p>There’s a time when the sun</p>

        <p>no longer feels warm</p>

        <p>and an extra layer of clothing</p>

        <p>replaces shorts and sandals</p>

        <p>for the first time since spring</p>

        <p>the furnace fires to ward off</p>

        <p>the morning chill before rising</p>

        <p>the sun rises ever later</p>

        <p>it’s still early for winter gear</p>

        <p>but lingering thick morning fog</p>

        <p>foreshadows frost and worse</p>

        <p>time to supplant cotton with wool</p>

        <p>the change in the air is not just weather</p>

        <p>it’s a far more frightening change,</p>
      </div>
    </Layout>
  );
};

export default Romain;
